import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import EnsightenAnalytics from "../Utility/EnsightenAnalytics";
import OneXButton from "../OneX/OneXButton";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { staticAppConfig } from "../AppData/Recoil";
import { useNavigate } from "react-router-dom";
import OneXSpecs from "../OneX/OneXSpecs";
import { postSelection } from "../Utility/API";
import { waitingForResponseState, emailState, emailSentState, jwtState, hasAccountState, receivedAccountStatusState } from "../AppData/Recoil";

const IdCardFound = () => {
  const [init, setInit] = useState(false);

  const setWaitingForResponse = useSetRecoilState(waitingForResponseState);
  const setEmail = useSetRecoilState(emailState);
  const setEmailSent = useSetRecoilState(emailSentState);
  const jwt = useRecoilValue(jwtState);
  const setHasAccount = useSetRecoilState(hasAccountState);
  const setReceivedAccountStatus = useSetRecoilState(receivedAccountStatusState);

  const isMobile = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.MOBILE);
  const isTablet = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.TABLET);
  const isDesktop = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.DESKTOP);
  const navigate = useNavigate();

  useEffect(() => {
    if (!init) {
      window.scrollTo(0, 0);
      EnsightenAnalytics.pushScreenChangeToAnalytics("idCardsFound");
      setInit(true);
    }
  }, [init, navigate]);

  const clickHandler = () => {
    postSelection(setWaitingForResponse, -1, setEmail, setEmailSent, navigate, jwt, setHasAccount, setReceivedAccountStatus).then(() => {
      navigate(staticAppConfig.routes.success);
    });
  };

  return (
    <div
      className={"-oneX-col-lg-6 -oneX-col-md-8 -oneX-col-sm-10"}
      style={{
        marginBottom: "128px",
        padding: isMobile ? "0px" : null,
      }}
    >
      <h1 style={{ marginTop: isDesktop ? "56px" : isTablet ? "48px" : isMobile ? "40px" : "40px" }}>Get your ID Cards</h1>
      <div className={"-oneX-body--intro"} style={{ marginTop: "48px", marginBottom: "32px" }}>
        <p>We found your policy (or policies). By clicking "Continue", you're agreeing to receive an email from State Farm for your auto ID Card.</p>
      </div>
      <OneXButton id={"found-continue-btn"} type={"primary"} clickHandler={clickHandler}>
        Continue
      </OneXButton>
    </div>
  );
};

export default IdCardFound;
